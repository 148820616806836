import React from 'react';

import { Button } from '@mui/material';
import * as classes from '../styles/getstarted.module.scss';
import Layout from '../components/Layout/Layout';
import employerImg from '../images/employeer_get_started.svg';
import expertImg from '../images/expert_get_started.svg';
import SEO from '../components/Seo';
import { Link } from 'gatsby';
import { getDomainURL,employer,windowlocation, recruiterUserType, candidateUserType} from '../constants';

export default function getStarted() {
  const location = windowlocation ;
  const copyrightsYear = new Date().getFullYear().toString();

  return (
    <Layout mode="empty">
      <SEO title="Get Started" />
      <div className={classes.container}>
        <div className={classes.mainContent}>
          <div className={classes.getStartedCard + ' ' + classes.employeCard}>
            <img src={employerImg} alt="Employeer" />
            <h1>Employers</h1>
            <p className={classes.info}>Hire top talent vetted by experts</p>
            <Button
              onClick={() => {
                window.location = getDomainURL(location, employer) + "?signup=true";
              }}
              variant="contained"
              color="secondary"
            >
              Join as an Employer
            </Button>
          </div>
          <div className={classes.getStartedCard + ' ' + classes.expertCard}>
            <img src={expertImg} alt="Employer" />
            <h1>Experts</h1>
            <p className={classes.info}>
            Earn by connecting talent with opportunity
            </p>
            <Button
              onClick={() => {
                window.location = getDomainURL(location,recruiterUserType) + "&signUp=true";
              }}
              variant="contained"
              color="secondary"
            >
              Join as an Expert Recruiter
            </Button>
          </div>

          <div className={classes.getStartedCard + ' ' + classes.employeCard}>
            <img src={expertImg} alt="Employer" />
            <h1>Job Seekers</h1>
            <p className={classes.info}>
            Get recommended by industry experts
            </p>
            <Button
              onClick={() => {
                window.location = getDomainURL(location,candidateUserType) + "&signUp=true";
              }}
              variant="contained"
              color="secondary"
            >
              Join as a Candidate
            </Button>
          </div>
        </div>
        <div className={classes.footer}>
          <p>
            <Link to="/terms_of_service">Terms and conditions</Link> |{' '}
            <Link to="/privacy_policy">Privacy policy</Link> |{' '}
            <Link to="/cookie_policy">Cookie policy</Link>
          </p>
          <div className={classes.copyBox}>
            <p>Copyright © {copyrightsYear} Experts Circle. All rights reserved.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
